// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// components
import SectionProjectSliderTemplate, {
  SectionProjectSliderTemplateProps,
} from './templates/SectionProjectSliderTemplate'

const SectionProjectSlider = ({
  ...props
}: Omit<SectionProjectSliderTemplateProps, 'projects'>) => {
  const data = useStaticQuery<Queries.SectionProjectSliderQuery>(graphql`
    query SectionProjectSlider {
      allDatoCmsProject(sort: { fields: meta___createdAt, order: DESC }) {
        edges {
          node {
            ...projectCard
          }
        }
      }
    }
  `)
  return (
    <SectionProjectSliderTemplate
      projects={data.allDatoCmsProject.edges}
      {...props}
    />
  )
}

export default memo(SectionProjectSlider)
